module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-cb27baa9d6/0/cache/gatsby-remark-autolink-headers-npm-5.24.0-b32f49afe6-3d2c4a745c.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/cache/gatsby-remark-images-medium-zoom-npm-1.7.0-baa145c3ea-354c026cb2.zip/node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"margin":36,"scrollOffset":0},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-cb27baa9d6/0/cache/gatsby-remark-autolink-headers-npm-5.24.0-b32f49afe6-3d2c4a745c.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-5ac3a6e274/0/cache/gatsby-plugin-google-analytics-npm-4.24.0-7f75d7608c-390fe9d54a.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-53906616-2","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-deefa7d0fe/0/cache/gatsby-plugin-canonical-urls-npm-4.24.0-e9b96bda57-0e136aee34.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.astralgate.net","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-4f2b43bccb/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
